.player{
    width:200px;
}

@media screen and (min-width:200px) {
    .player{
        width:250px;
    }
}

@media screen and (min-width:360px) {
    .player{
        width:320px;
    }
}

@media screen and (min-width:900px) {
    .player{
        width:640px;
    }
}


