*{
    font-family: 'Roboto',sans-serif;
}

img{ 
max-width: 100%;
}

a {
    text-decoration: none;
}

ui,li{
    list-style: none;
    padding: 0px;
}

 .banner_text{
     width:100%;

     @media screen and (min-width:900px){
         width:70%;
     }
 }

.MuiAccordion-root:before{
    display: none;
}
.option{
    display: flex;
    flex-wrap: wrap;
}
.singleOption{
    border-radius: 5px;
    width: 50%;
    padding: 20px 15px;
    padding: 8px 15px;
    color: #8d448b;
    background-color: #F8EFFF;
    width: 100%;
    height:100%;
    font-size: 12px;
    @media screen and (min-width: 900px) {
        padding: 15px 15px;
        font-size: 14px;
     }
}

.singleOption:hover{
    background-color: #8d448b;
    color: #fff;
}

.controls{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    @media screen and (min-width:900px){
        justify-content: flex-end;
    }
}

.nextButton{
    min-width:150px;
    min-height: 35px;
    @media screen and (min-width:900px){
        min-height: 45px;
    }
}

.select{
    background-color: #8d448b;
    color: #fff !important;
}

.quizHeader{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 30px;
}

.userName{
 font-size: 32px;
 font-weight: 500;
 color:#0f2137;
}

.userDomain{
    font-size: 20px;
    font-weight: 500;
    color:#343D48;
}

// .css-4ie59k-MuiButtonBase-root-MuiButton-root:hover{
//   background-color: #8d448b;
//   color: #fff;
// }



// Bagde

.ribbon{

    position: absolute;
    overflow: hidden;
    width: 150px;
    height: 150px;
    top: -10px;
      right: -10px;
}

.ribbon__content{
    left: -25px;
    top: 30px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    display: block;
    width: 225px;
    padding: 10px 0;
    background-color: #E14C38;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.192);
    color: #fff;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;
    border: 2px dotted #fff;
    outline : 5px solid  #E14C38;
}

// .ribbon::before {
//     top: 0;
//     left: 0;
//     border-top-color: transparent;
//     border-right-color: transparent;
//     position: absolute;
//     z-index: -1;
//     content: '';
//     display: block;
//     border: 5px solid #f19066;
//     box-sizing: content-box;

// }
// .ribbon::after {
//     bottom: 0;
//     right: 0;
//     border-top-color: transparent;
//     border-right-color: transparent;
//     position: absolute;
//     z-index: -1;
//     content: '';
//     display: block;
//     border: 5px solid #f19066;

// }

.css-b2hkxd-MuiButtonBase-root-MuiTab-root.Mui-selected{
    background-color:#8d448b;
    color:#fff
}

.css-heg063-MuiTabs-flexContainer{
    justify-content: center;
}


.package_container{
  margin-bottom: 4rem;
}

.package_plan {

    font-size:1rem;
    font-weight:500;
    text-align: center;
    padding:17px;
    border-top-left-radius:20px;
    border-top-right-radius:20px;
    color:#fff;
  }

.package_container{
   .package_1:nth-child(1){
    
    
    .package_plan{
        background-color:#61e99a;
    }
   
   }
}

.package_container{
    .package_1:nth-child(2){

        .package_plan{
            background-color:#7961e9 ;
        }
       
 }}

 .package_container{
    .package_1:nth-child(3){

        .package_plan{
            background-color: #e96161;
        }
       
 }}


 .error_container{
     display: flex;
     justify-content: center;
     align-items: center;
 }

 .error_bg{
      
       object-fit: cover;
       width:100%;
       height: auto;
       @media screen and (min-width: 576px) {
        height: 68vh;
        width: 50vw;
     }
       @media screen and (min-width: 1024px) {
        height: 68vh;
        width: 50vw;
     }
    
    }

    .title_text{
        width: 100%;
        text-align: center;
        font-size: 2rem;

        @media screen and (max-width: 576px) {
             font-size: 1.5rem;
          }
    }

    .button_container{
        display: inline-flex;
        width: 100%;
        text-align: center;
        justify-content: center;
    }

    .back_button{
      padding: 10px 20px;
      background-color: #39ac31;
      color: #fff;
      text-decoration: none;
      font-family: 'Roboto',sans-serif;
      margin-bottom: 24px;
    }


    //quiz styles
    .userName{
        font-size: 18px;
    }
    .userDomain{
        font-size: 18px;
    }
    .quizform{
        font-family: 'Roboto',sans-serif;
    }
    .question{

        font-family: inherit;
        font-size:16px;
        @media screen and (min-width: 900px) {
            font-size:20px;
         }
    }


    //custompage

    .custom_page{

        height:90vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #E4E7EC;
        flex-direction: column;
        
    }

    .custom_status{
        font-size:20px;
        font-weight: 500;
        color:#0f2137;
        margin: 20px 0px;
        @media screen and (min-width: 900px) {
            font-size:30px;
         }
    }


    .custom_message{
        font-size:15px;
        font-weight: 400;
        color:#0f2137;
        text-align:center;
        @media screen and (min-width: 900px) {
            font-size:16px;
         }
    }

  
    .custom_container{
        background-color: #fff;
        padding: 28px 20px;
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 4px;

        @media screen and (min-width:900px) {
            width: 70%;
            padding: 20px 15px;
        }
        @media screen and (min-width:1200px) {
            width: 50%;
            padding: 20px 15px;
        }
    }

    .checkmark{
        position:relative;

        &__check{
           position: absolute;
           top:50%;
           left:50%;
           z-index:10;
           fill:#fff;
           transform: translate3d(-50%,-50%,0);
        }

        &__background{
           fill:#2cb44a;
           animation: rotate 35s linear both infinite;
        }

        &__background__fail{
            fill:#cc2121;
            animation: rotate 35s linear both infinite;
         }
    }

    @keyframes rotate {
      0%{
          transform: rotate(0deg);
      }
      100%{
          transform: rotate(360deg);
      }
    }

    .pageLoader{
        height: 100vh;
        width:100%;
        display:flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }