:root {
  --swiper-theme-color: #8d448b;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner_container{
  display: flex;
}
.swiper_slider_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /*background-color: rgba(141,68,139,0.1);*/
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99;
}


.banner_content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.banner_text {
  font-size: 36px;
  font-weight: 600;
  color: #fff;
  margin: 0;
  margin-bottom: 24px;
} 

.banner_subtext{
  font-size:20px;
  color:#fff;
  margin:0rem 0 1rem 0rem;
}

@media screen and (max-width:1300px) {
  .banner_text {
    font-size: 25px;
    font-weight: 600;
    color: #fff;
    margin: 0;
    margin-bottom: 24px;
  }
}

@media screen and (max-width:900px) {
  .banner_text {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    margin: 0;
    margin-bottom: 24px;
  }
}

@media screen and (max-width:576px) {
  .swiper_slider_overlay {
    position: relative;
    background-color:#F8EFFF;
    min-height: 180px;
    padding-bottom: 20px;
  }

  .banner_content{
    width: auto;
    justify-content: flex-start;
  }

  .banner_container{
    display: flex;
    flex-direction: column;

    
  }
  .banner_text {
    font-size: 20px;
    font-weight: 600;
    color: #0f2137;
    margin: 20px 0px;
    width:90%;
  }
  .banner_subtext{
    font-size:16px;
    color: #0f2137;
  }
  
}


/* Popup */

.customModal{
  border-radius:5px;
  padding:.3rem !important;
}

.react-responsive-modal-closeButton
{
  background-color: #fff !important;
  border-radius: 50%;
}
