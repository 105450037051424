.pricingcard{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    border-radius: 12px;
    color: #343D48;
    background-color: #fff;
    width: 100%;
    height:100%;
    margin: auto;
    /* box-shadow: 0 18px 36px 0 rgb(213 215 225 / 49%); */
    box-shadow: 0 0 50px rgba(226, 222, 232, 0.75);
    transition: background-color 0.5s ease;
   
}

.card {
    padding: 15px 15px 0px 15px;
    width: 100%;
    height:100%;
  }



.feedback{
    font-size: 15px;
    text-align: justify;
    position: relative;
    margin-bottom: 12px;
    font-weight:400;
}

.feedback::before {
    content: '';
    position: absolute;
    background-image: url("../../assets/quote_active.svg");
    background-repeat: no-repeat;
    width: 40px;
    height: 30px;
    top: -28px;
    left: 5px;
    line-height: 1;
    font-size: 10rem;
    font-family: inherit;
    color:#8d448b91 ;
    z-index: 1;
}

.profile_wrapper{
    width: 65px;
    height: 65px;
    display: block;
    margin-bottom: 0.5rem;
}

@media only screen and  (min-width:1024px){
    .profile_wrapper{
       
        margin-bottom: 1rem;
    }
    
}
.profile{
    border-radius:50%;
    margin-bottom: 10px;
    border:3px solid #8d448b91;
    width: 65px;
    height: 65px;
}

.username{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0.2rem;
}

.user_type{
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 1.5rem;
}

.pricingcard:hover{
  
    color:#fff;
    background-color: #8d448b;
    cursor: pointer;
 
}

.pricingcard:hover .profile{
  
    border-color:#fff;
 
}

.pricingcard:hover .feedback::before{
   
    background-image: url("../../assets/quote_hover.svg");
 
}

.word_format{
  white-space:pre-wrap;
  word-break: break-word;
}