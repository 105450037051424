

.social_icon {
  background-color: #fff;
  border-radius: 50%;
  margin: 0px 10px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #505050;
  transition: background-color 0.5s ease-out;
}

@media only screen and (max-width: 600px) {
    .social_icon {
        margin: 0px 10px;
        width: 40px;
        height: 40px;
      }
  }


.social_icon:hover {
  color: #fff;
}

.social_icon.fb:hover {
  background-color: #4267B2;
}

.social_icon.inst:hover {
  background-color: #d6249f;
}

.social_icon.tw:hover {
  background-color: #1da1f2;
}

.social_icon.ln:hover {
  background-color: #00a0dc;
}
